import React, { Component } from 'react'

import SyncUserKeys from './Sync'

class Keys extends Component {
  constructor (props) {
    super(props)

    this.state = {
      operatorID: this.props.match.params.operatorID,
      memberID: this.props.match.params.memberID,
      refreshKey: 0
    }
    this.env = 'staging'
    if (/.*admin.evofitness.*/.test(window.location.origin)) {
      this.env = 'production'
    }

    this.syncRef = React.createRef();
    this.token = window.localStorage.getItem('auth-token')
    this.handleRefreshEvent = this.handleRefreshEvent.bind(this);
  }

  componentWillUnmount() {
    const syncElement = this.syncRef.current
    if (syncElement) {
      syncElement.removeEventListener('trigger-refresh', this.handleRefreshEvent)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Ensure event listener is added after the shadow dom is rendered
    if (!prevState.refreshKey && this.syncRef.current) {
      this.syncRef.current.addEventListener('trigger-refresh', this.handleRefreshEvent);
    }
  }

  handleRefreshEvent = (e) => {
    this.setState((prevState) => ({
      refreshKey: prevState.refreshKey + 1
    }))
  }

  render () {
    return (
      <div className='index'>
        <SyncUserKeys
          ref={this.syncRef}
          operatatorid={this.state.operatorID}
          membershipid={this.state.memberID}
        />
        <div className='content-box log'>
          <cl-user-key-list
            mountPath={`/operators/${this.state.operatorID}/members`}
            apiUrl={`https://access-control.${this.env}.credlock.net/api`}
            token={this.token}
            ownerid={this.state.memberID}
            operatorId={this.state.operatorID}
            refresh-key={this.state.refreshKey}
          ></cl-user-key-list>
        </div>
      </div>
    )
  }
}

export default Keys
